import React, { useState } from "react";
import { useSelector } from "react-redux";
// import mainloader from "tomitoken-staking\public\loader.gif";

function Loader({ text }) {
  const theme = useSelector((state) => state.UserReducer.theme);

  return (
    <>
      <div className="">
        <div
          className="position-fixed w-100 myloader"
          style={{
            zIndex: 1100,
            marginTop: -100,
            height: "120%",
            marginLeft: 0,
            background: "rgba(0, 0, 0, 0.6)",
          }}
        >
<div className="loaderrrrrrnew">
    <div className="d-flex flex-wrap align-items-center justify-content-center">
        <img width={150} className="mt-n4" src="/loa.gif" alt="" />
        <h2 className="text-white w-100 text-center mt-5">
            Please wait, your transaction is being processed
        </h2>
    </div>
</div>


        </div>
      </div>
    </>
  );
}
export default Loader;
