import "./navbar.scss";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import useAuth from "../../../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
const Navbar = () => {
  const { account } = useWeb3React();
  const [show2, setShow2] = useState(false);
  const handleShow3 = () => setShow3(true);
  const [show3, setShow3] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const [show1, setShow1] = useState(false);
  const { login, logout } = useAuth();

  const trustWallet = async () => {
    setShow1(false);
    login("walletconnect");
    localStorage.setItem("flag", "true");
    localStorage.setItem("connectorId", "walletconnect");
    // setShowconnect(false)
  };

  const connectMetaMask1 = () => {
    setShow1(false);
    login("injected");
    localStorage.setItem("connectorId", "injected");
    localStorage.setItem("flag", "true");
    // setShowconnect(false)
  };

  const logoutwallet = () => {
    const connectorId = window.localStorage.getItem("connectorId");
    logout(connectorId);
    localStorage.clear();
    localStorage.removeItem("connectorId");
    // window.location.reload();
  };
  return (
    <>
      <section className="main-navbar-mobile main-navbar container-fluid">
        <div className="main_headofnav">
          <div>
            <img src="\assets\Logo2.svg" alt="" className="img-fluid" />
          </div>
          <div>
            {account ? (
              <button onClick={logoutwallet}>Disconnect Wallet</button>
            ) : (
              <button onClick={handleShow1}>Connect Wallet</button>
            )}
          </div>
        </div>
      </section>

      <Modal className="categorymodal" show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Connect Your Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <div className="innermodal">
              <p><svg xmlns="http://www.w3.org/2000/svg" width="31" height="28" viewBox="0 0 31 28" fill="none">
                <g clip-path="url(#clip0_4_236)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6203 0.0461338C6.46163 0.980084 2.16199 4.96253 0.574439 10.2769C0.284927 11.2461 0.182433 12.2905 0.200181 14.0855C0.229243 16.9809 0.853303 19.3248 2.15577 21.4299L2.944 22.7035L7.31996 20.0683L11.6961 17.4333L11.7556 22.7854L11.8151 28.1375H14.0295H16.244V22.854C16.244 19.1678 16.3177 17.5704 16.4876 17.5704C16.6214 17.5704 18.5832 18.6984 20.8472 20.0773C24.0282 22.0147 25.0436 22.5338 25.3179 22.3629C25.6855 22.134 27.4891 18.7625 27.3207 18.619C27.2683 18.5745 24.2667 16.7446 20.6506 14.5527L14.0757 10.5676L9.64271 13.2822C7.2046 14.7751 5.08528 15.9966 4.93309 15.9966C4.72478 15.9966 4.67819 15.4536 4.74452 13.8045C4.82039 11.9202 4.93176 11.3986 5.53763 10.0894C8.49088 3.70819 16.6786 2.58853 21.3057 7.933C22.1949 8.96003 23.0506 10.8354 23.343 12.3986C23.467 13.0603 23.5923 13.6188 23.622 13.6399C23.6518 13.661 24.6244 14.2501 25.7835 14.9486L27.8911 16.2189L27.9603 14.3654C28.055 11.8307 27.7023 10.1734 26.56 7.79023C25.3726 5.31237 24.0666 3.77856 21.8856 2.3003C18.9961 0.341563 14.9254 -0.552368 11.6203 0.0461338Z" fill="#FF0083" />
                </g>
                <defs>
                  <clipPath id="clip0_4_236">
                    <rect width="30.1984" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg>tomi Wallet</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7 17L17 7M17 7H9M17 7V15" stroke="#FF0083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div> */}
            <div className="innermodal metamaskhidemobile" onClick={connectMetaMask1}>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31"
                  height="28"
                  viewBox="0 0 31 28"
                  fill="none"
                >
                  <g clip-path="url(#clip0_4_242)">
                    <path
                      d="M28.6678 0.0297241L16.9073 8.76404L19.0825 3.61075L28.6678 0.0297241Z"
                      fill="#E2761B"
                    />
                    <path
                      d="M1.51874 0.0297241L13.1847 8.8467L11.1159 3.61075L1.51874 0.0297241Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M24.4362 20.276L21.3041 25.0749L28.0059 26.9183L29.9326 20.3824L24.4362 20.276Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M0.27771 20.3824L2.1925 26.9183L8.89428 25.0749L5.76223 20.276L0.27771 20.3824Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M8.51607 12.1679L6.64825 14.9931L13.3025 15.2886L13.0664 8.13786L8.51607 12.1679Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M21.6705 12.1679L17.0613 8.05521L16.9073 15.2886L23.5502 14.9931L21.6705 12.1679Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M8.89429 25.0749L12.8892 23.1244L9.4378 20.4294L8.89429 25.0749Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M17.2974 23.1244L21.3042 25.0749L20.7488 20.4294L17.2974 23.1244Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M21.3042 25.0749L17.2974 23.1244L17.6167 25.7367L17.581 26.8357L21.3042 25.0749Z"
                      fill="#D7C1B3"
                    />
                    <path
                      d="M8.89429 25.0749L12.6174 26.8357L12.5936 25.7367L12.8892 23.1244L8.89429 25.0749Z"
                      fill="#D7C1B3"
                    />
                    <path
                      d="M12.6763 18.7043L9.34326 17.7231L11.6951 16.6474L12.6763 18.7043Z"
                      fill="#233447"
                    />
                    <path
                      d="M17.5103 18.7043L18.4914 16.6474L20.8552 17.7231L17.5103 18.7043Z"
                      fill="#233447"
                    />
                    <path
                      d="M8.89426 25.0749L9.46157 20.276L5.76221 20.3824L8.89426 25.0749Z"
                      fill="#CD6116"
                    />
                    <path
                      d="M20.7369 20.276L21.3042 25.0749L24.4362 20.3824L20.7369 20.276Z"
                      fill="#CD6116"
                    />
                    <path
                      d="M23.5502 14.9931L16.9073 15.2886L17.5222 18.7043L18.5033 16.6474L20.8671 17.7231L23.5502 14.9931Z"
                      fill="#CD6116"
                    />
                    <path
                      d="M9.34324 17.7231L11.707 16.6474L12.6763 18.7043L13.3025 15.2886L6.64825 14.9931L9.34324 17.7231Z"
                      fill="#CD6116"
                    />
                    <path
                      d="M6.64825 14.9931L9.43779 20.4294L9.34324 17.7231L6.64825 14.9931Z"
                      fill="#E4751F"
                    />
                    <path
                      d="M20.8671 17.7231L20.7488 20.4294L23.5502 14.9931L20.8671 17.7231Z"
                      fill="#E4751F"
                    />
                    <path
                      d="M13.3024 15.2886L12.6763 18.7043L13.4565 22.7343L13.6337 17.4276L13.3024 15.2886Z"
                      fill="#E4751F"
                    />
                    <path
                      d="M16.9072 15.2886L16.5885 17.4157L16.73 22.7343L17.5221 18.7043L16.9072 15.2886Z"
                      fill="#E4751F"
                    />
                    <path
                      d="M17.5221 18.7043L16.73 22.7343L17.2973 23.1244L20.7487 20.4294L20.8671 17.7231L17.5221 18.7043Z"
                      fill="#F6851B"
                    />
                    <path
                      d="M9.34326 17.7231L9.43781 20.4294L12.8892 23.1244L13.4565 22.7343L12.6763 18.7043L9.34326 17.7231Z"
                      fill="#F6851B"
                    />
                    <path
                      d="M17.581 26.8357L17.6167 25.7367L17.3212 25.4763H12.8654L12.5936 25.7367L12.6174 26.8357L8.89429 25.0749L10.1942 26.1381L12.8297 27.9703H17.3568L20.0043 26.1381L21.3042 25.0749L17.581 26.8357Z"
                      fill="#C0AD9E"
                    />
                    <path
                      d="M17.2974 23.1244L16.7301 22.7343H13.4565L12.8892 23.1244L12.5936 25.7367L12.8654 25.4763H17.3211L17.6167 25.7367L17.2974 23.1244Z"
                      fill="#161616"
                    />
                    <path
                      d="M29.1643 9.33134L30.1687 4.50927L28.6678 0.0297241L17.2974 8.4685L21.6705 12.1679L27.8519 13.9762L29.2232 12.3807L28.6321 11.9556L29.5776 11.0927L28.845 10.5254L29.7905 9.80409L29.1643 9.33134Z"
                      fill="#763D16"
                    />
                    <path
                      d="M0.0297241 4.50927L1.0341 9.33134L0.396033 9.80409L1.34154 10.5254L0.620813 11.0927L1.56632 11.9556L0.975228 12.3807L2.33461 13.9762L8.51607 12.1679L12.8892 8.4685L1.51874 0.0297241L0.0297241 4.50927Z"
                      fill="#763D16"
                    />
                    <path
                      d="M27.852 13.9762L21.6705 12.1678L23.5502 14.9931L20.7488 20.4294L24.4362 20.3824H29.9327L27.852 13.9762Z"
                      fill="#F6851B"
                    />
                    <path
                      d="M8.51608 12.1678L2.33463 13.9762L0.27771 20.3824H5.76223L9.4378 20.4294L6.64827 14.9931L8.51608 12.1678Z"
                      fill="#F6851B"
                    />
                    <path
                      d="M16.9073 15.2886L17.2974 8.4685L19.0944 3.61075H11.1159L12.8892 8.4685L13.3025 15.2886L13.4446 17.4395L13.4565 22.7343H16.7301L16.7538 17.4395L16.9073 15.2886Z"
                      fill="#F6851B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4_242">
                      <rect width="30.1984" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Metamask
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7 17L17 7M17 7H9M17 7V15"
                  stroke="#FF0083"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="innermodal" onClick={trustWallet}>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31"
                  height="28"
                  viewBox="0 0 31 28"
                  fill="none"
                >
                  <g clip-path="url(#clip0_4_277)">
                    <path
                      d="M6.32324 8.68407C11.3525 3.77198 19.5166 3.77198 24.5459 8.68407L25.1514 9.27977C25.4053 9.52391 25.4053 9.9243 25.1514 10.1684L23.081 12.1899C22.9541 12.3169 22.749 12.3169 22.6221 12.1899L21.792 11.3794C18.2764 7.95165 12.5928 7.95165 9.07714 11.3794L8.18847 12.2485C8.06152 12.3755 7.85644 12.3755 7.72949 12.2485L5.65918 10.227C5.40527 9.9829 5.40527 9.58251 5.65918 9.33837L6.32324 8.68407ZM28.833 12.8637L30.6787 14.6606C30.9326 14.9048 30.9326 15.3052 30.6787 15.5493L22.3682 23.6645C22.1142 23.9086 21.7041 23.9086 21.46 23.6645L15.5615 17.9028C15.5029 17.8442 15.3955 17.8442 15.3369 17.9028L9.43847 23.6645C9.18457 23.9086 8.77441 23.9086 8.53027 23.6645L0.19043 15.5493C-0.0634765 15.3052 -0.0634765 14.9048 0.19043 14.6606L2.03613 12.8637C2.29004 12.6196 2.7002 12.6196 2.94434 12.8637L8.84277 18.6255C8.90136 18.684 9.00878 18.684 9.06738 18.6255L14.9658 12.8637C15.2197 12.6196 15.6299 12.6196 15.874 12.8637L21.7724 18.6255C21.831 18.684 21.9385 18.684 21.9971 18.6255L27.8955 12.8637C28.1689 12.6196 28.5791 12.6196 28.833 12.8637Z"
                      fill="#0270FF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4_277">
                      <rect width="30.1984" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Connect Wallet
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7 17L17 7M17 7H9M17 7V15"
                  stroke="#FF0083"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
