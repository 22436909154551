import web3NoAccount from "./web3";
import tomiclaim from "./pioneerClaimAbi.json";
import pioneerNft from "./pioneerNftAbi.json";

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount;
  // console.log('_web3',_web3);s
  return new _web3.eth.Contract(abi, address);
};

export const getPioneerClaim = (address, web3) => {
  return getContract(tomiclaim, address, web3);
};

export const PioneerNft = (address, web3) => {
  return getContract(pioneerNft, address, web3);
};


