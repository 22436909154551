import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getPioneerClaim } from "../../utils/contractHelpers";

export const GetBalance = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.pioneerClaim;
  const contract = getPioneerClaim(tokenAddress, web3);
  const GetBal = useCallback(
    async (id) => {
      console.log("address", id);
      const approved = await contract.methods.hasClaimedTokens(id).call();
      // let balance = web3.utils.fromWei(approved, "ether")
      // let balance = parseInt(approved);
      // console.log("balance", approved)
      return approved;
    },
    [contract]
  );

  return { GetBal: GetBal };
};

export default GetBalance;
