import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import Environment from "../../utils/Environment";
// import { hydtAbi } from "../../utils/contractHelpers";
import { PioneerNft } from "../../utils/contractHelpers";

export const ApproveAllow = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contractAddress = Environment.pioneer;
  const contract = PioneerNft(contractAddress, web3);

  const ApproveTokenAllow = useCallback(async () => {
    try {
      const balance = await contract.methods
        .setApprovalForAll(Environment.pioneerClaim, "true")
        .send({ from: account });
      console.log("eeeeeeeeee", balance);
      return balance;
    } catch (error) {
      console.log("9999999", error);
      throw error;
    }
  }, [contract]);
  return { ApproveTokenAllow: ApproveTokenAllow };
};
export default ApproveAllow;
