import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import { PioneerNft } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";

export const TomiAllowance = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contractAddress = Environment.pioneer;
  const contract = PioneerNft(contractAddress, web3);
  const AllowanceTomi = useCallback(async () => {
    // let val = web3.utils.toWei(spendBnb.toString(), "ether");
    try {
      const balance = await contract.methods
        .isApprovedForAll(account, Environment.pioneerClaim)
        .call();
      // let bal = web3.utils.fromWei(balance, "ether")
      return balance;
    } catch (error) {
      console.log("9999999", error);
      throw error;
    }
  }, [contract]);
  return { AllowanceTomi: AllowanceTomi };
};
export default TomiAllowance;
