import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./banner.scss";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../../hooks/loader";
import Table from "react-bootstrap/Table";
import useWeb3 from "../../../hooks/useWeb3";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import Modal from "react-bootstrap/Modal";
import { GetBalance } from "../../../hooks/dataFetchers/getBalance";
import { ApproveAllow } from "../../../hooks/dataSenders/approvedAllowence";
import { TomiAllowance } from "../../../hooks/dataFetchers/allowence";
import nftIDJson from "../../wallet.json";
import { toast } from "react-toastify";
import { Navbar } from "react-bootstrap";
import Environment from "../../../utils/Environment";
import { getPioneerClaim } from "../../../utils/contractHelpers";

const Banner = () => {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [allowance, setAllowance] = useState(false);
  const [claimedData, setClaimedData] = useState([]);
  const handleShow2 = () => setShow2(true);
  const [mainLoader, setMainLoader] = useState(false);
  const [nftIDJson, setNftIDJson] = useState({
    wallets: "",
    nftIds: [],
  });

  const [count, setCount] = useState(0);
  const { GetBal } = GetBalance();
  const [nftIds, setNftIds] = useState([]);
  const [finalResult, setFinalResult] = useState([]);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [claimAll, setClaimAll] = useState(false);
  const [allClaimed, setAllClaimed] = useState(false);
  const { ApproveTokenAllow } = ApproveAllow();
  const { AllowanceTomi } = TomiAllowance();
  const web3 = useWeb3();

  console.log("json", selectedNfts, nftIDJson);

  const handleIncrement = () => {
    setCount(count + 1); // Increment the count
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1); // Decrement the count, ensuring it doesn't go below 0
    }
  };

  const trustWallet = async () => {
    setShow1(false);
    if (account) {
      logout("walletconnect");
      localStorage.clear();
    } else {
      login("walletconnect");
      localStorage.setItem("flag", "true");
      localStorage.setItem("connectorId", "walletconnect");
      // setShowconnect(false)
    }
  };

  const connectMetaMask1 = () => {
    setShow1(false);
    if (account) {
      logout();
      localStorage.clear();
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      // setShowconnect(false)
    }
  };

  const checkNftStatuses = async (nfts) => {
    console.log("here", nfts);
    const results = [];
    for (const nft of nfts) {
      try {
        const result = await GetBal(nft); // Call GetBal function with the NFT ID
        results.push({ id: nft, isClaimed: result, token: 15000 }); // Save the NFT ID and result
      } catch (error) {
        console.error(`Error fetching balance for NFT ${nft}:`, error);
      }
    }
    const allClaimed = results.every((nft) => nft.isClaimed === true);
    setAllClaimed(allClaimed);
    console.log("alllll", allClaimed);
    setFinalResult(results);
  };

  const handleCheckboxChange = (nftId, isChecked) => {
    setSelectedNfts((prevSelected) => {
      if (isChecked) {
        // Add the NFT ID if checked
        return [...prevSelected, nftId];
      } else {
        // Remove the NFT ID if unchecked
        return prevSelected.filter((id) => id !== nftId);
      }
    });
  };

  const filterUnclaimedNfts = async (nftIds) => {
    const unclaimedNfts = [];

    for (const nft of nftIds) {
      try {
        const result = await GetBal(nft);
        // console.log("result111111", result);
        // Only add to the list if the NFT has not been claimed
        if (!result) {
          // console.log("pushssss", nft);
          unclaimedNfts.push(nft);
        }
      } catch (error) {
        console.error(`Error fetching balance for NFT ${nft}:`, error);
      }
    }

    return unclaimedNfts;
  };

  const handleCheckboxAll = (nftID, isChecked) => {
    setSelectedNfts(() => {
      if (isChecked) {
        setClaimAll(true);
        setSelectedNfts(nftIds);
      } else {
        // Remove the NFT ID if unchecked
        setSelectedNfts([]);
        setClaimAll(false);
      }
    });
  };

  const ApproveFun = async () => {
    const contractAddress = Environment.pioneerClaim;
    const contract = getPioneerClaim(contractAddress, web3);
    try {
      // console.log("approve")
      if (account) {
        setMainLoader(true);
        let result = await ApproveTokenAllow();
        console.log("approve console", result);
        if (result) {
          try {
            let gasPrice = await web3.eth.getGasPrice();
            const gas = await contract.methods
              .claim(selectedNfts)
              .estimateGas({ from: account, gasPrice });
            const balance = await contract.methods
              .claim(selectedNfts)
              .send({ from: account, gas, gasPrice });
            // const balance = await contract.methods.stakeForAPY(bal, update).send({ from: account })
            if (balance) {
              setMainLoader(false);
              handleShow3();
              handleClose2();
              getClaimData();
            }
          } catch (error) {
            console.log("inswap", error);
            setMainLoader(false);
            handleShow4();
          }
          setMainLoader(false);
        }
      }
    } catch {
      setMainLoader(false);
    }
  };

  const StackingAllowenceCheck = async () => {
    try {
      // setMainLoader(true);
      if (account) {
        let result = await AllowanceTomi();
        console.log("result allowance", result);
        setAllowance(result);
        setMainLoader(false);
      }
    } catch {
      setMainLoader(false);
    }
  };

  const swap = async () => {
    if (account) {
      try {
        if (allowance == true) {
          setMainLoader(true);
          const contractAddress = Environment.pioneerClaim;
          const contract = getPioneerClaim(contractAddress, web3);
          try {
            let gasPrice = await web3.eth.getGasPrice();
            // console.log("gaasssss", selectedNfts);
            const gas = await contract.methods.claim(selectedNfts).estimateGas({ from: account });
            // let gasgpricenew = gas + 1000000
            const balance = await contract.methods
              .claim(selectedNfts)
              .send({ from: account, gas, gasPrice });
            // const balance = await contract.methods.stakeForAPY(bal, update).send({ from: account })
            if (balance) {
              setMainLoader(false);
              getClaimData();
              handleShow3();
              handleClose2();
            }
          } catch (error) {
            console.log("inswap", error);

            setMainLoader(false);
            handleShow4();
            // if (error.code === -32000) {
            //   toast.warning("Insufficient Eth For Fee", {
            //     position: "top-center",
            //     autoClose: 4000,
            //   });
            //   console.log("9999999", typeof error.code);
            //   throw error;
            // } else {
            //   toast.error(error.message, {
            //     position: "top-center",
            //     autoClose: 4000,
            //   });
            //   console.log("9999999", error);
            //   throw error;
            // }
          }
        } else {
          ApproveFun();
        }
      } catch {
        setMainLoader(false);
        console.log("inswap1");
      }
    }
  };

  const popuppp = () => {
    if (allClaimed == true) {
      toast.error("No NFT's to Swap", {
        position: "top-center",
        autoClose: 5000,
      });
    } else if (selectedNfts?.length > 0) {
      handleShow2();
    } else {
      toast.error("Please Select atleast 1 nft for Swap", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const closee = () => {
    handleClose4();
    handleClose2();
  };
  const closee1 = () => {
    handleClose3();
    window.location.reload();
  };

  const userNFt = () => {
    if (account) {
      const options = {
        method: "GET",
        url: `https://deep-index.moralis.io/api/v2/${account}/nft?chain=eth&format=decimal&normalizeMetadata=false&token_addresses=${Environment.pioneer}`,
        headers: {
          accept: "application/json",
          "X-API-Key":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MTFmMDdiLWZkNGMtNDBmZS05MjQ0LWU5ZmNlOWVlNjY2MiIsIm9yZ0lkIjoiMjA5OTMxIiwidXNlcklkIjoiMjA5NjAzIiwidHlwZUlkIjoiNjgzMjA5NjgtZTEzNy00MTA1LWFjNDYtM2ZlNGRmNDViMGZlIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTY0OTAzNDEsImV4cCI6NDg3MjI1MDM0MX0.GR-teydWjr1g7RLcBh_x1QC-ECgy7Z6e-nTmGiE2qJ8",
        },
      };
      const a = axios.request(options).then(function (response) {
        console.log("b=nfttttt", response.data.result);
        const uniqueTokenIds = [...new Set(response.data.result.map((nft) => nft.token_id))];
        setNftIDJson({
          wallets: account,
          nftIds: uniqueTokenIds,
        });
        setNftIds(uniqueTokenIds);
        checkNftStatuses(uniqueTokenIds);

        return response.data.result;
      });
      return a.catch(function (error) {
        console.error(error);
      });
    }
  };

  useEffect(() => {
    // setMainLoader(true);
    userNFt();
    StackingAllowenceCheck();
    getClaimData();
    const checkWalletAndNfts = async () => {
      StackingAllowenceCheck();
      if (account && nftIDJson?.wallets?.length > 0) {
        // const unclaimedNftIds = await filterUnclaimedNfts(matchingWallet.nftIds);
        // console.log("unnnn", unclaimedNftIds);

        // if (unclaimedNftIds.length > 0) {
        setNftIds(nftIDJson.nftIds);
        checkNftStatuses(nftIDJson.nftIds);
        // }
      }
    };
    checkWalletAndNfts();
  }, [account]);

  const [showMore, setShowMore] = useState(false);

  const getClaimData = () => {
    // setMainLoader(true);
    let acc = JSON.stringify(account);
    var data = JSON.stringify({
      query: `query MyQuery {
  pioneerNftClaimeds( where: {user: ${acc}}orderDirection: desc, orderBy: blockTimestamp) {
    nftIds
    token
    transactionHash
    blockTimestamp
  }
}`,
    });
    var config = {
      method: "post",
      url: Environment.graphclaim,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log(response, "graph");

        setClaimedData(response?.data?.data?.pioneerNftClaimeds);
        // setMainLoader(false);
      })
      .catch(function (error) {
        setMainLoader(false);
      });
  };

  const handleReadMoreClick = () => {
    setShowMore(true);
  };
  const [activeTab, setActiveTab] = useState("home");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      {mainLoader && <Loader />}
      <section className="main-banner">
        <img className="elipse img-fluid" alt="" src="\assets\Ellipsemid.svg" />
        <div className="custom-container">
          <Navbar />
          <div className="headddddofmain">
            <div className="main_head">
              <p className="tomipp">1 tomi pioneer = 15,000 tomi tokens</p>
              <div className="innnnerdata">
                <div className="upper_part">
                  <img className="imggpart" alt="asd" src="\assets\bannerimg.png" />
                  <p>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M10.9254 18.9583C5.98371 18.9583 1.96704 14.9416 1.96704 9.99996C1.96704 5.05829 5.98371 1.04163 10.9254 1.04163C15.867 1.04163 19.8837 5.05829 19.8837 9.99996C19.8837 14.9416 15.867 18.9583 10.9254 18.9583ZM10.9254 2.29163C6.67537 2.29163 3.21704 5.74996 3.21704 9.99996C3.21704 14.25 6.67537 17.7083 10.9254 17.7083C15.1754 17.7083 18.6337 14.25 18.6337 9.99996C18.6337 5.74996 15.1754 2.29163 10.9254 2.29163Z"
                        fill="#FF0004"
                      />
                      <path
                        d="M10.9254 11.4583C10.5837 11.4583 10.3004 11.175 10.3004 10.8333V6.66663C10.3004 6.32496 10.5837 6.04163 10.9254 6.04163C11.267 6.04163 11.5504 6.32496 11.5504 6.66663V10.8333C11.5504 11.175 11.267 11.4583 10.9254 11.4583Z"
                        fill="#FF0004"
                      />
                      <path
                        d="M10.9254 14.1667C10.817 14.1667 10.7087 14.1417 10.6087 14.1C10.5087 14.0583 10.417 14 10.3337 13.925C10.2587 13.8416 10.2004 13.7583 10.1587 13.65C10.117 13.55 10.092 13.4417 10.092 13.3333C10.092 13.225 10.117 13.1167 10.1587 13.0167C10.2004 12.9167 10.2587 12.825 10.3337 12.7417C10.417 12.6667 10.5087 12.6083 10.6087 12.5667C10.8087 12.4833 11.042 12.4833 11.242 12.5667C11.342 12.6083 11.4337 12.6667 11.517 12.7417C11.592 12.825 11.6504 12.9167 11.692 13.0167C11.7337 13.1167 11.7587 13.225 11.7587 13.3333C11.7587 13.4417 11.7337 13.55 11.692 13.65C11.6504 13.7583 11.592 13.8416 11.517 13.925C11.4337 14 11.342 14.0583 11.242 14.1C11.142 14.1417 11.0337 14.1667 10.9254 14.1667Z"
                        fill="#FF0004"
                      />
                    </svg>{" "}
                    Users will not be able to swap NFTs after 3 months
                  </p>
                </div>
                {/* <div className="lower_part">
                <h1>Swap tomi pioneer for tomi tokens</h1>
                <div className="partsecond">
                  <button className="plusssdiv" onClick={handleDecrement}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M6.92529 12H18.9253"
                      stroke="white"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                  <input
                    placeholder="2"
                    type="text"
                    className="inputmint"
                    value={count} 
                    readOnly 
                  />

                  <button className="plusssdiv" onClick={handleIncrement}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M6.92529 12H18.9253"
                      stroke="white"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.9253 18V6"
                      stroke="white"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                </div>
                {account ? (
                  <div className="innnner_partr">
                    <p>You have {count} tomi pioneer NFTs in your wallet</p>
                    <div className="ouuuuter">
                      <div className="part1">
                        <h3>NFTs for swap</h3>
                        <h3>{count}</h3>
                      </div>
                      <div className="part2">
                        <h3>$TOMI you will receieve</h3>
                        <h3>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <rect x="0.925293" width="20" height="20" rx="10" fill="#171717" />
                            <path
                              d="M10.7671 5.79274C13.21 5.79274 15.2015 7.76574 15.2934 10.2358L17.4365 11.5786C17.5005 11.197 17.5337 10.8051 17.5337 10.4048C17.5337 6.59137 14.5044 3.5 10.7666 3.5C7.02878 3.5 4 6.59187 4 10.4052C4 11.2309 4.142 12.0231 4.40291 12.7564C4.66092 13.4838 5.03543 14.1538 5.50332 14.7442L9.64598 12.1479V17.4377H11.8295V12.1052L16.0342 14.7407C16.5016 14.1504 16.8757 13.4799 17.1332 12.752L10.7719 8.76526L10.7671 8.7682L6.36834 11.5246C6.28217 11.169 6.23644 10.7982 6.23644 10.4156C6.23644 7.8625 8.26493 5.79324 10.7666 5.79324L10.7671 5.79274ZM10.7671 11.4391L10.7714 11.4416L10.7671 11.4445V11.4387V11.4391Z"
                              fill="#FF0083"
                            />
                          </svg>
                          {(count * 15000).toLocaleString()} <span>TOMI</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>Connect wallet to see how many tomi pioneer NFTs you own</p>
                )}

                {account ? (
                  <button onClick={logoutwallet}>Disconnect Wallet</button>
                ) : (
                  <button onClick={handleShow1}>Connect Wallet</button>
                )}
                <button onClick={handleShow2} className="processss">
                  PROCEED
                </button>
              </div> */}
                <div className="bottompart">
                  <div className="bottominner">
                    <div className="paragraphdetail">
                      <p>
                        The daily issuance of Pioneers and the entire Pioneer system will be
                        canceled.
                      </p>
                      <p>
                        The primary function of the Pioneers, which was to control the DAO and vote
                        on project decisions, will be transferred to the broader community. This
                        means that the DAO will now be controlled by all TOMI token holders,
                        ensuring a more decentralized and democratic governance structure. A revised
                        set of DAO operation rules will be determined in the updated project
                        whitepaper.
                      </p>
                      <p>
                        As compensation for canceling the Pioneer system, each of the 1,500 Pioneers
                        will receive a one-time issuance of 15,000 TOMI tokens.
                      </p>
                      <a
                        className="pinktext"
                        href="https://dao.tomi.com/proposal/20"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read More
                      </a>
                    </div>
                  </div>

                  {account ? "" : <button onClick={handleShow1}>Connect Wallet to proceed</button>}
                </div>
              </div>
            </div>
          </div>
          {account ? (
            <div className="tabbbledata">
              <div className="outttertabs">
                <ul className="nav nav-pills mb-3 mytabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                      onClick={() => handleTabClick("home")}
                      role="tab"
                    >
                      Claim Details
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${activeTab === "profile" ? "active" : ""}`}
                      onClick={() => handleTabClick("profile")}
                      role="tab"
                    >
                      Claim History
                    </button>
                  </li>
                </ul>
              </div>

              <div className="tab-content">
                {activeTab === "home" && (
                  <div className="tab-pane fade show active">
                    <div className="firsttab">
                      <div className="claimbutoontab">
                        <button onClick={popuppp}>Claim ({selectedNfts?.length})</button>
                      </div>

                      <div className="table-responsive">
                        <Table className="mytableeee neww">
                          <thead>
                            <tr>
                              <th>
                                <div className="thhhdata">
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleCheckboxAll(selectedNfts, e.target.checked)
                                      }
                                    />
                                  </label>
                                  Select All
                                </div>
                              </th>
                              <th>
                                <div className="nftdiv">
                                  NFT ID
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="14"
                                    viewBox="0 0 10 14"
                                    fill="none"
                                  >
                                    <path
                                      d="M8.72207 7.90088H4.71308H1.10306C0.485304 7.90088 0.176425 8.72136 0.614003 9.20233L3.94732 12.8662C4.48142 13.4533 5.35014 13.4533 5.88425 12.8662L7.15194 11.4728L9.21756 9.20233C9.64871 8.72136 9.33983 7.90088 8.72207 7.90088Z"
                                      fill="#4E4E4E"
                                    />
                                    <path
                                      d="M8.72207 6.09888H4.71308H1.10306C0.485304 6.09888 0.176425 5.2784 0.614003 4.79742L3.94732 1.13355C4.48142 0.546479 5.35014 0.546479 5.88425 1.13355L7.15194 2.52695L9.21756 4.79742C9.64871 5.2784 9.33983 6.09888 8.72207 6.09888Z"
                                      fill="#4E4E4E"
                                    />
                                  </svg>
                                </div>
                              </th>
                              <th>Claimable Tokens</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              finalResult?.length > 0
                                ? finalResult?.map((elem) => (
                                    <tr>
                                      <td>
                                        <div>
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={
                                                (claimAll && elem?.isClaimed == true) ||
                                                selectedNfts?.includes(elem?.id) ||
                                                elem.isClaimed == true
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(elem?.id, e?.target.checked)
                                              }
                                              disabled={claimAll}
                                            />
                                          </label>
                                        </div>
                                      </td>
                                      <td>{elem?.id}</td>
                                      <td>{elem?.token?.toLocaleString()} tomi</td>
                                      <td>
                                        <div
                                          className={
                                            elem?.isClaimed == false ? "unclaimed" : "claimed"
                                          }
                                        >
                                          {elem?.isClaimed == false ? "Unclaimed" : "Claimed"}
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                : ""
                              // (<p>No NFT results yet.</p> )
                            }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "profile" && (
                  <div className="tab2">
                    <div className="table-responsive">
                      <Table className="mytableeee neww">
                        <thead>
                          <tr>
                            <th>
                              <div className="nftdiv">
                                Date
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="14"
                                  viewBox="0 0 10 14"
                                  fill="none"
                                >
                                  <path
                                    d="M8.72207 7.90088H4.71308H1.10306C0.485304 7.90088 0.176425 8.72136 0.614003 9.20233L3.94732 12.8662C4.48142 13.4533 5.35014 13.4533 5.88425 12.8662L7.15194 11.4728L9.21756 9.20233C9.64871 8.72136 9.33983 7.90088 8.72207 7.90088Z"
                                    fill="#4E4E4E"
                                  />
                                  <path
                                    d="M8.72207 6.09888H4.71308H1.10306C0.485304 6.09888 0.176425 5.2784 0.614003 4.79742L3.94732 1.13355C4.48142 0.546479 5.35014 0.546479 5.88425 1.13355L7.15194 2.52695L9.21756 4.79742C9.64871 5.2784 9.33983 6.09888 8.72207 6.09888Z"
                                    fill="#4E4E4E"
                                  />
                                </svg>
                              </div>
                            </th>
                            <th>
                              <div className="nftdiv">
                                Token ID
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="14"
                                  viewBox="0 0 10 14"
                                  fill="none"
                                >
                                  <path
                                    d="M8.72207 7.90088H4.71308H1.10306C0.485304 7.90088 0.176425 8.72136 0.614003 9.20233L3.94732 12.8662C4.48142 13.4533 5.35014 13.4533 5.88425 12.8662L7.15194 11.4728L9.21756 9.20233C9.64871 8.72136 9.33983 7.90088 8.72207 7.90088Z"
                                    fill="#4E4E4E"
                                  />
                                  <path
                                    d="M8.72207 6.09888H4.71308H1.10306C0.485304 6.09888 0.176425 5.2784 0.614003 4.79742L3.94732 1.13355C4.48142 0.546479 5.35014 0.546479 5.88425 1.13355L7.15194 2.52695L9.21756 4.79742C9.64871 5.2784 9.33983 6.09888 8.72207 6.09888Z"
                                    fill="#4E4E4E"
                                  />
                                </svg>
                              </div>
                            </th>
                            <th>
                              <div className="nftdiv">
                                Transaction hash
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="14"
                                  viewBox="0 0 10 14"
                                  fill="none"
                                >
                                  <path
                                    d="M8.72207 7.90088H4.71308H1.10306C0.485304 7.90088 0.176425 8.72136 0.614003 9.20233L3.94732 12.8662C4.48142 13.4533 5.35014 13.4533 5.88425 12.8662L7.15194 11.4728L9.21756 9.20233C9.64871 8.72136 9.33983 7.90088 8.72207 7.90088Z"
                                    fill="#4E4E4E"
                                  />
                                  <path
                                    d="M8.72207 6.09888H4.71308H1.10306C0.485304 6.09888 0.176425 5.2784 0.614003 4.79742L3.94732 1.13355C4.48142 0.546479 5.35014 0.546479 5.88425 1.13355L7.15194 2.52695L9.21756 4.79742C9.64871 5.2784 9.33983 6.09888 8.72207 6.09888Z"
                                    fill="#4E4E4E"
                                  />
                                </svg>
                              </div>
                            </th>
                            <th>
                              <div className="nftdiv">
                                Total Tomi Claimed
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="14"
                                  viewBox="0 0 10 14"
                                  fill="none"
                                >
                                  <path
                                    d="M8.72207 7.90088H4.71308H1.10306C0.485304 7.90088 0.176425 8.72136 0.614003 9.20233L3.94732 12.8662C4.48142 13.4533 5.35014 13.4533 5.88425 12.8662L7.15194 11.4728L9.21756 9.20233C9.64871 8.72136 9.33983 7.90088 8.72207 7.90088Z"
                                    fill="#4E4E4E"
                                  />
                                  <path
                                    d="M8.72207 6.09888H4.71308H1.10306C0.485304 6.09888 0.176425 5.2784 0.614003 4.79742L3.94732 1.13355C4.48142 0.546479 5.35014 0.546479 5.88425 1.13355L7.15194 2.52695L9.21756 4.79742C9.64871 5.2784 9.33983 6.09888 8.72207 6.09888Z"
                                    fill="#4E4E4E"
                                  />
                                </svg>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            claimedData?.length > 0
                              ? claimedData?.map((elem) => (
                                  <tr>
                                    <td>
                                      {new Date(elem?.blockTimestamp * 1000).toLocaleDateString()}
                                    </td>
                                    <td>{elem?.nftIds?.join(", ")}</td>

                                    <td>
                                      <div className="copuyyy">
                                        {elem?.transactionHash.slice(0, 6)}...
                                        {elem?.transactionHash.slice(-4)}{" "}
                                        <a
                                          href={`https://etherscan.io/tx/${elem?.transactionHash}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <svg
                                            className="svvvggss"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="17"
                                            viewBox="0 0 16 17"
                                            fill="none"
                                          >
                                            <path
                                              d="M6.66672 3.36601H5.46672C4.71998 3.36601 4.34633 3.36601 4.06112 3.51133C3.81023 3.63916 3.60641 3.84299 3.47858 4.09387C3.33325 4.37909 3.33325 4.75273 3.33325 5.49947V10.5661C3.33325 11.3129 3.33325 11.686 3.47858 11.9713C3.60641 12.2221 3.81023 12.4263 4.06112 12.5542C4.34605 12.6993 4.71925 12.6993 5.46452 12.6993H10.5353C11.2806 12.6993 11.6532 12.6993 11.9382 12.5542C12.1891 12.4263 12.3936 12.222 12.5214 11.9711C12.6666 11.6861 12.6666 11.3133 12.6666 10.5681V9.36601M13.3333 6.03267V2.69934M13.3333 2.69934H9.99992M13.3333 2.69934L8.66659 7.36601"
                                              stroke="#9D9D9D"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </a>
                                      </div>
                                    </td>
                                    <td>{parseFloat(elem?.token) / 10 ** 18} tomi</td>
                                  </tr>
                                ))
                              : ""
                            // (<p>No NFT results yet.</p> )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>

      <Modal className="categorymodal" show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Connect Your Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <div className="innermodal">
              <p><svg xmlns="http://www.w3.org/2000/svg" width="31" height="28" viewBox="0 0 31 28" fill="none">
                <g clip-path="url(#clip0_4_236)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6203 0.0461338C6.46163 0.980084 2.16199 4.96253 0.574439 10.2769C0.284927 11.2461 0.182433 12.2905 0.200181 14.0855C0.229243 16.9809 0.853303 19.3248 2.15577 21.4299L2.944 22.7035L7.31996 20.0683L11.6961 17.4333L11.7556 22.7854L11.8151 28.1375H14.0295H16.244V22.854C16.244 19.1678 16.3177 17.5704 16.4876 17.5704C16.6214 17.5704 18.5832 18.6984 20.8472 20.0773C24.0282 22.0147 25.0436 22.5338 25.3179 22.3629C25.6855 22.134 27.4891 18.7625 27.3207 18.619C27.2683 18.5745 24.2667 16.7446 20.6506 14.5527L14.0757 10.5676L9.64271 13.2822C7.2046 14.7751 5.08528 15.9966 4.93309 15.9966C4.72478 15.9966 4.67819 15.4536 4.74452 13.8045C4.82039 11.9202 4.93176 11.3986 5.53763 10.0894C8.49088 3.70819 16.6786 2.58853 21.3057 7.933C22.1949 8.96003 23.0506 10.8354 23.343 12.3986C23.467 13.0603 23.5923 13.6188 23.622 13.6399C23.6518 13.661 24.6244 14.2501 25.7835 14.9486L27.8911 16.2189L27.9603 14.3654C28.055 11.8307 27.7023 10.1734 26.56 7.79023C25.3726 5.31237 24.0666 3.77856 21.8856 2.3003C18.9961 0.341563 14.9254 -0.552368 11.6203 0.0461338Z" fill="#FF0083" />
                </g>
                <defs>
                  <clipPath id="clip0_4_236">
                    <rect width="30.1984" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg>tomi Wallet</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7 17L17 7M17 7H9M17 7V15" stroke="#FF0083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div> */}
            <div className="innermodal" onClick={connectMetaMask1}>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31"
                  height="28"
                  viewBox="0 0 31 28"
                  fill="none"
                >
                  <g clip-path="url(#clip0_4_242)">
                    <path
                      d="M28.6678 0.0297241L16.9073 8.76404L19.0825 3.61075L28.6678 0.0297241Z"
                      fill="#E2761B"
                    />
                    <path
                      d="M1.51874 0.0297241L13.1847 8.8467L11.1159 3.61075L1.51874 0.0297241Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M24.4362 20.276L21.3041 25.0749L28.0059 26.9183L29.9326 20.3824L24.4362 20.276Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M0.27771 20.3824L2.1925 26.9183L8.89428 25.0749L5.76223 20.276L0.27771 20.3824Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M8.51607 12.1679L6.64825 14.9931L13.3025 15.2886L13.0664 8.13786L8.51607 12.1679Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M21.6705 12.1679L17.0613 8.05521L16.9073 15.2886L23.5502 14.9931L21.6705 12.1679Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M8.89429 25.0749L12.8892 23.1244L9.4378 20.4294L8.89429 25.0749Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M17.2974 23.1244L21.3042 25.0749L20.7488 20.4294L17.2974 23.1244Z"
                      fill="#E4761B"
                    />
                    <path
                      d="M21.3042 25.0749L17.2974 23.1244L17.6167 25.7367L17.581 26.8357L21.3042 25.0749Z"
                      fill="#D7C1B3"
                    />
                    <path
                      d="M8.89429 25.0749L12.6174 26.8357L12.5936 25.7367L12.8892 23.1244L8.89429 25.0749Z"
                      fill="#D7C1B3"
                    />
                    <path
                      d="M12.6763 18.7043L9.34326 17.7231L11.6951 16.6474L12.6763 18.7043Z"
                      fill="#233447"
                    />
                    <path
                      d="M17.5103 18.7043L18.4914 16.6474L20.8552 17.7231L17.5103 18.7043Z"
                      fill="#233447"
                    />
                    <path
                      d="M8.89426 25.0749L9.46157 20.276L5.76221 20.3824L8.89426 25.0749Z"
                      fill="#CD6116"
                    />
                    <path
                      d="M20.7369 20.276L21.3042 25.0749L24.4362 20.3824L20.7369 20.276Z"
                      fill="#CD6116"
                    />
                    <path
                      d="M23.5502 14.9931L16.9073 15.2886L17.5222 18.7043L18.5033 16.6474L20.8671 17.7231L23.5502 14.9931Z"
                      fill="#CD6116"
                    />
                    <path
                      d="M9.34324 17.7231L11.707 16.6474L12.6763 18.7043L13.3025 15.2886L6.64825 14.9931L9.34324 17.7231Z"
                      fill="#CD6116"
                    />
                    <path
                      d="M6.64825 14.9931L9.43779 20.4294L9.34324 17.7231L6.64825 14.9931Z"
                      fill="#E4751F"
                    />
                    <path
                      d="M20.8671 17.7231L20.7488 20.4294L23.5502 14.9931L20.8671 17.7231Z"
                      fill="#E4751F"
                    />
                    <path
                      d="M13.3024 15.2886L12.6763 18.7043L13.4565 22.7343L13.6337 17.4276L13.3024 15.2886Z"
                      fill="#E4751F"
                    />
                    <path
                      d="M16.9072 15.2886L16.5885 17.4157L16.73 22.7343L17.5221 18.7043L16.9072 15.2886Z"
                      fill="#E4751F"
                    />
                    <path
                      d="M17.5221 18.7043L16.73 22.7343L17.2973 23.1244L20.7487 20.4294L20.8671 17.7231L17.5221 18.7043Z"
                      fill="#F6851B"
                    />
                    <path
                      d="M9.34326 17.7231L9.43781 20.4294L12.8892 23.1244L13.4565 22.7343L12.6763 18.7043L9.34326 17.7231Z"
                      fill="#F6851B"
                    />
                    <path
                      d="M17.581 26.8357L17.6167 25.7367L17.3212 25.4763H12.8654L12.5936 25.7367L12.6174 26.8357L8.89429 25.0749L10.1942 26.1381L12.8297 27.9703H17.3568L20.0043 26.1381L21.3042 25.0749L17.581 26.8357Z"
                      fill="#C0AD9E"
                    />
                    <path
                      d="M17.2974 23.1244L16.7301 22.7343H13.4565L12.8892 23.1244L12.5936 25.7367L12.8654 25.4763H17.3211L17.6167 25.7367L17.2974 23.1244Z"
                      fill="#161616"
                    />
                    <path
                      d="M29.1643 9.33134L30.1687 4.50927L28.6678 0.0297241L17.2974 8.4685L21.6705 12.1679L27.8519 13.9762L29.2232 12.3807L28.6321 11.9556L29.5776 11.0927L28.845 10.5254L29.7905 9.80409L29.1643 9.33134Z"
                      fill="#763D16"
                    />
                    <path
                      d="M0.0297241 4.50927L1.0341 9.33134L0.396033 9.80409L1.34154 10.5254L0.620813 11.0927L1.56632 11.9556L0.975228 12.3807L2.33461 13.9762L8.51607 12.1679L12.8892 8.4685L1.51874 0.0297241L0.0297241 4.50927Z"
                      fill="#763D16"
                    />
                    <path
                      d="M27.852 13.9762L21.6705 12.1678L23.5502 14.9931L20.7488 20.4294L24.4362 20.3824H29.9327L27.852 13.9762Z"
                      fill="#F6851B"
                    />
                    <path
                      d="M8.51608 12.1678L2.33463 13.9762L0.27771 20.3824H5.76223L9.4378 20.4294L6.64827 14.9931L8.51608 12.1678Z"
                      fill="#F6851B"
                    />
                    <path
                      d="M16.9073 15.2886L17.2974 8.4685L19.0944 3.61075H11.1159L12.8892 8.4685L13.3025 15.2886L13.4446 17.4395L13.4565 22.7343H16.7301L16.7538 17.4395L16.9073 15.2886Z"
                      fill="#F6851B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4_242">
                      <rect width="30.1984" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Metamask
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7 17L17 7M17 7H9M17 7V15"
                  stroke="#FF0083"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="innermodal" onClick={trustWallet}>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31"
                  height="28"
                  viewBox="0 0 31 28"
                  fill="none"
                >
                  <g clip-path="url(#clip0_4_277)">
                    <path
                      d="M6.32324 8.68407C11.3525 3.77198 19.5166 3.77198 24.5459 8.68407L25.1514 9.27977C25.4053 9.52391 25.4053 9.9243 25.1514 10.1684L23.081 12.1899C22.9541 12.3169 22.749 12.3169 22.6221 12.1899L21.792 11.3794C18.2764 7.95165 12.5928 7.95165 9.07714 11.3794L8.18847 12.2485C8.06152 12.3755 7.85644 12.3755 7.72949 12.2485L5.65918 10.227C5.40527 9.9829 5.40527 9.58251 5.65918 9.33837L6.32324 8.68407ZM28.833 12.8637L30.6787 14.6606C30.9326 14.9048 30.9326 15.3052 30.6787 15.5493L22.3682 23.6645C22.1142 23.9086 21.7041 23.9086 21.46 23.6645L15.5615 17.9028C15.5029 17.8442 15.3955 17.8442 15.3369 17.9028L9.43847 23.6645C9.18457 23.9086 8.77441 23.9086 8.53027 23.6645L0.19043 15.5493C-0.0634765 15.3052 -0.0634765 14.9048 0.19043 14.6606L2.03613 12.8637C2.29004 12.6196 2.7002 12.6196 2.94434 12.8637L8.84277 18.6255C8.90136 18.684 9.00878 18.684 9.06738 18.6255L14.9658 12.8637C15.2197 12.6196 15.6299 12.6196 15.874 12.8637L21.7724 18.6255C21.831 18.684 21.9385 18.684 21.9971 18.6255L27.8955 12.8637C28.1689 12.6196 28.5791 12.6196 28.833 12.8637Z"
                      fill="#0270FF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4_277">
                      <rect width="30.1984" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Connect Wallet
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7 17L17 7M17 7H9M17 7V15"
                  stroke="#FF0083"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="categorymodal2" show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>Swap Pioneer NFT for tomi tokens</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="datttta">
            <img className="img-fluid  imgmoadaal" src="\assets\modalimg.png" />
            <h1>Are you sure?</h1>
            <h2>
              You will send your tomi Pioneer NFT and receive $TOMI instead. You will not get your
              NFT back. Are you sure you want to continue?
            </h2>
            <div className="innnner_partr">
              <div className="ouuuuter">
                <div className="part1">
                  <h3>NFTs to swap</h3>
                  <h3>{selectedNfts?.length}</h3>
                </div>
                <div className="part2">
                  <h3>$TOMI you will receive</h3>
                  <h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <rect x="0.925293" width="20" height="20" rx="10" fill="#171717" />
                      <path
                        d="M10.7671 5.79274C13.21 5.79274 15.2015 7.76574 15.2934 10.2358L17.4365 11.5786C17.5005 11.197 17.5337 10.8051 17.5337 10.4048C17.5337 6.59137 14.5044 3.5 10.7666 3.5C7.02878 3.5 4 6.59187 4 10.4052C4 11.2309 4.142 12.0231 4.40291 12.7564C4.66092 13.4838 5.03543 14.1538 5.50332 14.7442L9.64598 12.1479V17.4377H11.8295V12.1052L16.0342 14.7407C16.5016 14.1504 16.8757 13.4799 17.1332 12.752L10.7719 8.76526L10.7671 8.7682L6.36834 11.5246C6.28217 11.169 6.23644 10.7982 6.23644 10.4156C6.23644 7.8625 8.26493 5.79324 10.7666 5.79324L10.7671 5.79274ZM10.7671 11.4391L10.7714 11.4416L10.7671 11.4445V11.4387V11.4391Z"
                        fill="#FF0083"
                      />
                    </svg>
                    {(selectedNfts?.length * 15000).toLocaleString()} <span>TOMI</span>
                  </h3>
                </div>
              </div>
            </div>
            <button onClick={swap}>Continue</button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="categorymodal" show={show3} onHide={handleClose3} centered>
        <Modal.Header closeButton>
          <Modal.Title>Swap Pioneer NFTs for tomi tokens</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="datttta">
            <img src="\assets\success.png" alt="" />
            <h6>You have successfully Swapped </h6>
            <h5>
              {selectedNfts?.length} tomi Pioneer NFT(s) <span className="spanfor"> for </span>{" "}
              {(selectedNfts?.length * 15000).toLocaleString()} tomi tokens
            </h5>
            <button onClick={closee1}>Done</button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="categorymodal" show={show4} onHide={handleClose4} centered>
        <Modal.Header closeButton>
          <Modal.Title>Swap Pioneer NFT for tomi tokens</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="datttta">
            <img className="imgcross" src="\assets\delete.png" alt="" />
            <h4>Transaction failed </h4>
            <button onClick={closee}>Go back</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;
